import React from 'react'
import { Container } from '../elements'

const Footer = () => (
  <footer
    id="contact"
    style={{
      background: 'var(--background-color-primary)',
      color: 'var(--color-primary)',
    }}
  >
    <Container>
      <div className="pt-8 lg:flex lg:pt-12 lg:pb-16">
        <div className="pb-4 sm:pb-8 pt-16 lg:w-1/2">
          <div className="flex items-center no-underline">
            <svg
              className="fill-current h-8 mr-2 w-8"
              width="54"
              height="54"
              viewBox="0 0 210 210"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="A"
                  transform="translate(5.000000, 5.000000)"
                  fill="currentColor"
                >
                  <circle
                    id="Oval"
                    stroke="currentColor"
                    strokeWidth="10"
                    fillOpacity="0"
                    cx="100"
                    cy="100"
                    r="100"
                  />
                  <path
                    d="M151.238044,160.121429 L124.809473,160.121429 C122.761906,159.986594 120.990446,158.647472 120.30233,156.714286 L83.1737587,42.4071429 C82.5666158,40.5357143 83.6023301,39 85.4737587,39 L111.90233,39 C113.941904,39.1430774 115.703009,40.4807321 116.388044,42.4071429 L153.530902,156.735714 C154.145187,158.585714 153.109473,160.121429 151.238044,160.121429 Z"
                    id="Path"
                  />
                  <path
                    d="M81.2809015,71.0474812 C81.0071158,70.4781386 80.594441,69.9868592 80.0809015,69.6189098 C79.0356855,68.8699522 77.6511024,68.7947334 76.5309015,69.4260526 C75.9179241,69.7720405 75.4604846,70.3393677 75.2523301,71.0117669 L47.1737587,155.854624 C46.5666158,157.726053 47.6023301,159.261767 49.4737587,159.261767 L75.9023301,159.261767 C77.9419038,159.11869 79.703009,157.781035 80.3880444,155.854624 L93.3523301,114.097481 C94.2880444,111.604624 93.9023301,109.811767 93.2380444,107.361767"
                    id="Path"
                  />
                </g>
              </g>
            </svg>
            <span className="font-bold text-xl tracking-tight">
              Absolute Code
            </span>
          </div>
        </div>
        <div className="pt-4 flex flex-col sm:flex-row sm:w-full sm:justify-between sm:pb-16 lg:flex lg:inline-flex lg:pt-16">
          <ul className="my-6 sm:my-2 sm:pr-4">
            <li className="uppercase font-bold text-xs xl:text-sm mb-6 leading-tight tracking-wider">
              Adres
            </li>
            <li
              style={{
                color: 'var(--color-tertiary)',
              }}
              className="text-sm xl:text-base my-1"
            >
              Noordewierweg 109
            </li>
            <li
              style={{
                color: 'var(--color-tertiary)',
              }}
              className="text-sm xl:text-base my-1"
            >
              3812 DD AMERSFOORT
            </li>
          </ul>
          <ul className="my-6 sm:my-2 sm:px-4">
            <li className="uppercase font-bold text-xs xl:text-sm mb-6 leading-tight tracking-wider">
              Email
            </li>
            <li
              style={{
                color: 'var(--color-tertiary)',
              }}
              className="text-sm xl:text-base my-1 hover:underline"
            >
              <a href="mailto:info@absolutecode.nl">info@absolutecode.nl</a>
            </li>
          </ul>
          <ul className="my-6 sm:my-2 sm:px-4">
            <li className="uppercase font-bold text-xs xl:text-sm mb-6 leading-tight tracking-wider">
              BTW
            </li>
            <li
              style={{
                color: 'var(--color-tertiary)',
              }}
              className="text-sm xl:text-base my-1"
            >
              NL002176629B47
            </li>
          </ul>
          <ul className="my-6 sm:my-2 sm:pl-4">
            <li className="uppercase font-bold text-xs xl:text-sm mb-6 leading-tight tracking-wider">
              KvK
            </li>
            <li
              style={{
                color: 'var(--color-tertiary)',
              }}
              className="text-sm xl:text-base my-1"
            >
              71139249
            </li>
          </ul>
        </div>
      </div>
    </Container>
    <div
      style={{
        background: 'var(--background-color-primary)',
        color: 'var(--color-secondary)',
      }}
    >
      <Container className="flex flex-col-reverse sm:flex-row justify-between pt-4 sm:pt-10 pb-10">
        <p
          style={{
            color: 'var(--color-quaternary)',
          }}
          className="flex items-center text-sm xl:text-base mt-8 sm:mt-0"
        >
          &copy; {new Date().getFullYear()} Absolute Code
        </p>
        <ul
          style={{
            color: 'var(--color-quaternary)',
          }}
          className="flex items-center"
        >
          <li className="mr-4 sm:mr-6">
            <a
              href="https://twitter.com/rrzwarte"
              target="_blank"
              title="Twitter"
              rel="noopener noreferrer"
            >
              <svg
                className="h-6 fill-current"
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Twitter</title>
                <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
              </svg>
            </a>
          </li>
          <li className="mr-4 sm:mr-6">
            <a
              href="https://github.com/rrzwarte"
              target="_blank"
              title="GitHub"
              rel="noopener noreferrer"
            >
              <svg
                className="h-6 fill-current"
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>GitHub</title>
                <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/remcozwartepoorte/"
              target="_blank"
              title="LinkedIn"
              rel="noopener noreferrer"
            >
              <svg
                className="fill-current h-6 w-6"
                width="54"
                height="54"
                viewBox="0 0 144 144"
                version="1.1"
              >
                <defs>
                  <polygon
                    id="path-1"
                    points="0 0 144.004 0 144.004 143.94 0 143.94"
                  />
                </defs>
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g id="In-White-2in-TM">
                    <g id="Group-3">
                      <g id="Clip-2" />
                      <path
                        d="M122.708,122.707 L101.364,122.707 L101.364,89.287 C101.364,81.318 101.218,71.067 90.265,71.067 C79.152,71.067 77.446,79.748 77.446,88.711 L77.446,122.707 L56.106,122.707 L56.106,53.985 L76.591,53.985 L76.591,63.375 L76.877,63.375 C79.729,57.973 86.696,52.277 97.087,52.277 C118.715,52.277 122.708,66.511 122.708,85.013 L122.708,122.707 Z M32.034,44.591 C25.188,44.591 19.648,39.042 19.648,32.205 C19.648,25.374 25.188,19.825 32.034,19.825 C38.863,19.825 44.412,25.374 44.412,32.205 C44.412,39.042 38.863,44.591 32.034,44.591 Z M21.35,122.707 L42.71,122.707 L42.71,53.985 L21.35,53.985 L21.35,122.707 Z M133.345,0 L10.629,0 C4.765,0 0,4.648 0,10.376 L0,133.61 C0,139.345 4.765,144 10.629,144 L133.345,144 C139.219,144 144.004,139.345 144.004,133.61 L144.004,10.376 C144.004,4.648 139.219,0 133.345,0 Z"
                        id="LinkedIn"
                        fill="currentColor"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </li>
        </ul>
      </Container>
    </div>
  </footer>
)

export default Footer
