import React from 'react'
import styled from 'styled-components'
import {
  color,
  space,
  layout,
  typography,
  variant,
  textStyle,
  grid,
} from 'styled-system'
import theme from '../../config/theme'

export const Box = styled.div`
  ${color}
  ${space}
  ${layout}
`

Box.defaultProps = {}

export const Text = styled.p`
  ${typography}
  ${color}
  ${space}
  ${textStyle}
`

export const Grid = styled.div`
  display: grid;
  ${grid}
`

Text.defaultProps = {
  color: 'var(--color-secondary)',
  fontFamily: 'normal',
  fontWeight: 'normal',
  fontSize: [2, null, 3],
  mb: '4',
}

export const Title = styled.h1`
  ${typography}
  ${color}
  ${space}
`

Title.defaultProps = {
  color: 'var(--color-primary)',
  fontFamily: 'normal',
  fontWeight: 'bold',
  fontSize: [7, null, 8],
  lineHeight: '1',
  mb: '6',
  letterSpacing: '-0.025em',
}

export const Container = styled.div`
  max-width: 80rem;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 0 auto;
  ${space}


  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    width: 90%;
  }
`
