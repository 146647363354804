import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { MDXProvider } from '@mdx-js/react'
import { Text, Title } from '../elements'

import theme from '../../config/theme'
import 'prism-theme-night-owl'

// import '../../fonts/montserrat-v12-latin/montserrat-v12-latin.css'
// import '../../fonts/proxima-nova/proxima-nova.css'

import Header from './header'
import Footer from './footer'

const MDXComponents = {
  // h1: props => <Title as="h1" {...props}></Title>,
  h1: props => (
    <Title as="h2" fontSize={[5, null, 6]} mb={4} {...props}></Title>
  ),
  p: props => <Text {...props}></Text>,
}

const GlobalStyle = createGlobalStyle`
  :root {
    /* color-scheme: light dark; */
    --background-color-primary: ${props => props.theme.colors.white};
    --background-color-secondary: ${props => props.theme.colors.gray[0]};

    --color-primary: ${props => props.theme.colors.gray[9]};
    --color-secondary: ${props => props.theme.colors.gray[6]};
    --color-tertiary: ${props => props.theme.colors.gray[5]};
    --color-quaternary:${props => props.theme.colors.gray[4]};
    --color-white: ${props => props.theme.colors.white};
    --color-black: ${props => props.theme.colors.black};
    --color-transparent: ${props => props.theme.colors.transparent};

    --link-color: ${props => props.theme.colors.blue[6]};
    --accent-color-blue: ${props => props.theme.colors.blue[5]};

    --input-background-color: ${props => props.theme.colors.white};
    --input-border-color: ${props => props.theme.colors.gray[1]};
    --form-background-color: ${props => props.theme.colors.gray[0]};
  }


@media (prefers-color-scheme: dark) {
  :root {
    --background-color-primary: ${props => props.theme.colors.gray[8]};
    --background-color-secondary: ${props => props.theme.colors.gray[7]};

    --color-primary: ${props => props.theme.colors.gray[1]};
    --color-secondary: ${props => props.theme.colors.gray[2]};
    --color-tertiary: ${props => props.theme.colors.gray[3]};
    --color-quaternary:${props => props.theme.colors.gray[4]};
    --color-white: ${props => props.theme.colors.white};
    --color-black: ${props => props.theme.colors.black};
    --color-transparent: ${props => props.theme.colors.transparent};

    --link-color: ${props => props.theme.colors.blue[5]};
    --accent-color-blue: ${props => props.theme.colors.blue[5]};

    --input-background-color: ${props => props.theme.colors.gray[5]};
    --input-border-color: ${props => props.theme.colors.gray[4]};
    --form-background-color: ${props => props.theme.colors.gray[7]};
  }
}

html {
  /* font-size: 16px; */
  scroll-behavior: smooth;
  
  /* @media screen and (min-width: 768px) {
    font-size: 16px;
  } */
}

body {
  color: var(--color-primary);
  background-color: var(--background-color-primary)
}

.gatsby-highlight pre[class*="language-"] {
  margin-bottom: 1.75rem;
  border-radius: 10px;
  line-height: normal;

  code {
  font-family: ${props => props.theme.fonts.mono};
  font-size: 0.85rem;
  }
}

`

const Layout = ({ isInverted, children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data, ...props) => (
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            <Header
              siteTitle={data.site.siteMetadata.title}
              isInverted={isInverted}
            />
            <MDXProvider components={MDXComponents}>
              <main>{children}</main>
            </MDXProvider>
            <Footer />
          </>
        </ThemeProvider>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
