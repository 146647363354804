import React, { useState, useEffect, createContext } from 'react'

const InterfaceContext = createContext()

export const InterfaceProvider = ({ children }) => {
  const [isDarkMode, setDarkMode] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches
  )

  function handleChange(e) {
    if (e.matches) {
      setDarkMode(true)
    } else {
      setDarkMode(false)
    }
  }

  useEffect(() => {
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)')
    darkModeQuery.addListener(handleChange)
    return () => {
      darkModeQuery.removeListener(handleChange)
    }
  })
  return (
    <InterfaceContext.Provider value={{ isDarkMode }}>
      {children}
    </InterfaceContext.Provider>
  )
}

export default InterfaceContext
