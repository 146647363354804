import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import { Container, Box, Title, Text, Grid } from '../elements'
import theme from '../../config/theme'

const Color = ({ color }) => {
  const colorVariable = `var(${color})`
  // const colorValue = getComputedStyle(document.body).getPropertyValue(color)
  // const darkValue = window.matchMedia('(prefers-color-scheme: dark)')
  // .getComputedStyle(document.body)
  // .getPropertyValue(color)
  return (
    <Box style={{ display: 'flex' }}>
      <Box bg={colorVariable} width="100px" height="100px"></Box>
      <div style={{ marginLeft: '20px' }}>
        <span
          style={{ display: 'block', fontWeight: 'bold' }}
        >{`${color}`}</span>
        {/* <span
          style={{ display: 'block', marginBottom: '20px' }}
        >{`${colorValue}`}</span> */}
      </div>
    </Box>
  )
}

const StyleGuide = () => (
  <>
    <Box py={8}>
      <Title>Typography</Title>
      <Title>Title</Title>
      <Text>Text</Text>
    </Box>
    <Box my={8}>
      <Title>Color</Title>
      <Title as="h2" fontSize={4}>
        Global CSS Variables
      </Title>
      <Grid gridGap={4} gridTemplateColumns="1fr 1fr">
        <Color color="--background-color-primary"></Color>
        <Color color="--background-color-secondary"></Color>
        <Color color="--color-primary"></Color>
        <Color color="--color-secondary"></Color>
        <Color color="--color-tertiary"></Color>
        <Color color="--color-quaternary"></Color>
        <Color color="--color-white"></Color>
        <Color color="--color-black"></Color>
        <Color color="--color-transparent"></Color>
        <Color color="--link-color"></Color>
        <Color color="--accent-color-blue"></Color>
        <Color color="--input-background-color"></Color>
        <Color color="--input-border-color"></Color>
        <Color color="--form-background-color"></Color>
      </Grid>
    </Box>
  </>
)

export default StyleGuide
