import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Container } from '../elements'

const activeStyle = {
  borderColor: 'var(--color-primary)',
}

const StyledHeader = styled.header`
  --nav-background-color: rgba(35, 101, 215, 0.98);

  @media (prefers-color-scheme: dark) {
    --nav-background-color: rgba(29, 89, 200, 0.980392);
  }

  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  color: ${props =>
    props.isMobileNavOpen
      ? props.theme.colors.white
      : props.isInverted
      ? props.theme.colors.white
      : 'var(--color-primary)'};
`

const Button = styled.button`
  background-color: var(--color-transparent);
  border: 0px none;
  display: block;
  margin-left: auto;
  margin-top: -6px;
  padding: 0px;
  position: ${props => (props.isMobileNavOpen ? 'fixed' : 'relative')};
  right: ${props => (props.isMobileNavOpen ? '2rem' : 'none')};
  z-index: 22;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;

  @media screen and (min-width: 550px) {
    right: ${props => (props.isMobileNavOpen ? 'calc(2rem + 5vw)' : 'none')};
  }

  :focus {
    outline: 0;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const Hamburger = styled.div`
  width: 24px;
  height: 3px;
  margin: 10px 0px;
  background-color: ${props =>
    props.isInverted
      ? props.isMobileNavOpen
        ? 'var(--color-transparent)'
        : 'white'
      : props.isMobileNavOpen
      ? 'var(--color-transparent)'
      : 'var(--color-primary)'};
  position: relative;
  z-index: 1000;
  border-radius: 2px;
  transition: all 250ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;

  &:before {
    content: ' ';
    top: ${props => (props.isMobileNavOpen ? '0px' : '-7px')};
    width: ${props => (props.isMobileNavOpen ? '24px' : '20px')};
    right: 0px;
    border-radius: 2px;
    height: 3px;
    background-color: ${props =>
      props.isInverted
        ? 'white'
        : props.isMobileNavOpen
        ? 'white'
        : 'var(--color-primary)'};
    position: absolute;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    transform: ${props => (props.isMobileNavOpen ? 'rotate(45deg)' : 'none')};

    ${Button}:hover & {
      width: 24px;
      top: ${props => (props.isMobileNavOpen ? '0px' : '-9px')};
    }
  }

  &::after {
    content: ' ';
    top: ${props => (props.isMobileNavOpen ? '0px' : '7px')};
    width: ${props => (props.isMobileNavOpen ? '24px' : '16px')};
    right: 0px;
    border-radius: 2px;
    height: 3px;
    background-color: ${props =>
      props.isInverted
        ? 'white'
        : props.isMobileNavOpen
        ? 'white'
        : 'var(--color-primary)'};
    position: absolute;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    transform: ${props => (props.isMobileNavOpen ? 'rotate(-45deg)' : 'none')};

    ${Button}:hover & {
      width: 24px;
      top: ${props => (props.isMobileNavOpen ? '0px' : '9px')};
    }
  }
`

const Nav = styled.nav`
  display: ${props => (props.isMobileNavOpen ? 'block' : 'none')};
  position: fixed;
  overflow-y: auto;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  margin: 0px;
  background-color: var(--nav-background-color);
  padding: 5rem 3.5rem;
  align-items: center;
  text-align: right;
  z-index: 1;

  @media screen and (min-width: 768px) {
    text-align: left;
    display: flex;
    background-color: var(--color-transparent);
    position: static;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    margin: 0px 0px 0px auto;
    padding: 0px;
    flex-grow: 0;
    overflow: visible;
  }
`

const Header = ({ siteTitle, isInverted }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  return (
    <StyledHeader isMobileNavOpen={isMobileNavOpen} isInverted={isInverted}>
      <Container
        css={`
          height: 5rem;
          @media screen and (min-width: 550px) {
            padding-top: 3rem;
          }
        `}
      >
        <div className="flex flex-wrap items-center justify-between h-full">
          <Link to="/" className="flex items-center no-underline">
            <svg
              className="fill-current mr-2 text-3xl xl:text-4xl"
              width="1em"
              height="1em"
              viewBox="0 0 50 50"
              version="1.1"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Oval"
                  fill={isInverted ? 'white' : 'var(--accent-color-blue)'}
                >
                  <path
                    d="M24.7142857,49.4285714 C11.0649626,49.4285714 -3.55271368e-15,38.3636088 -3.55271368e-15,24.7142857 C-3.55271368e-15,11.0649626 11.0649626,0 24.7142857,0 C38.3636088,0 49.4285714,11.0649626 49.4285714,24.7142857 C49.4285714,38.3636088 38.3636088,49.4285714 24.7142857,49.4285714 Z M19.9008032,16.2693523 C19.8304012,16.1229499 19.7242848,15.9966209 19.5922318,15.9020054 C19.323462,15.7094163 18.9674263,15.6900743 18.6793747,15.8524135 C18.5217519,15.9413818 18.4041246,16.087266 18.3505992,16.2601686 L11.1303951,38.0769033 C10.9742726,38.5581279 11.2405992,38.9530258 11.7218237,38.9530258 L18.517742,38.9530258 C19.0422038,38.9162346 19.4950595,38.5722661 19.6712114,38.0769033 L23.0048849,27.3393523 C23.2454971,26.6983319 23.1463135,26.2373115 22.9754971,25.6073115 L19.9008032,16.2693523 Z M37.8897827,39.1740817 C38.3710073,39.1740817 38.6373338,38.7791836 38.4793748,38.3034693 L28.9283542,8.90469389 C28.7522023,8.40933111 28.2993467,8.06536276 27.7748849,8.02857143 L20.9789665,8.02857143 C20.497742,8.02857143 20.2314155,8.42346939 20.387538,8.90469389 L29.9348849,38.2979593 C30.111829,38.7950642 30.5673473,39.1394099 31.0938645,39.1740817 L37.8897827,39.1740817 Z"
                    id="Oval-2"
                  ></path>
                  <path
                    d="M19.9008032,16.2693523 L22.9754971,25.6073115 C23.1463135,26.2373115 23.2454971,26.6983319 23.0048849,27.3393523 L19.6712114,38.0769033 C19.4950595,38.5722661 19.0422038,38.9162346 18.517742,38.9530258 L11.7218237,38.9530258 C11.2405992,38.9530258 10.9742726,38.5581279 11.1303951,38.0769033 L18.3505992,16.2601686 C18.4041246,16.087266 18.5217519,15.9413818 18.6793747,15.8524135 C18.9674263,15.6900743 19.323462,15.7094163 19.5922318,15.9020054 C19.7242848,15.9966209 19.8304012,16.1229499 19.9008032,16.2693523 Z M37.8897827,39.1740817 L31.0938645,39.1740817 C30.5673473,39.1394099 30.111829,38.7950642 29.9348849,38.2979593 L20.387538,8.90469389 C20.2314155,8.42346939 20.497742,8.02857143 20.9789665,8.02857143 L27.7748849,8.02857143 C28.2993467,8.06536276 28.7522023,8.40933111 28.9283542,8.90469389 L38.4793748,38.3034693 C38.6373338,38.7791836 38.3710073,39.1740817 37.8897827,39.1740817 Z"
                    id="Path-2"
                    fill={isInverted ? 'var(--color-transparent)' : 'white'}
                  />
                </g>
              </g>
            </svg>
            {/* <svg
            className="fill-current h-8 mr-2 w-8"
            width="54"
            height="54"
            viewBox="0 0 210 210"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="A"
                transform="translate(5.000000, 5.000000)"
                fill="currentColor"
              >
                <circle
                  id="Oval"
                  stroke="currentColor"
                  strokeWidth="10"
                  fillOpacity="0"
                  cx="100"
                  cy="100"
                  r="100"
                />
                <path
                  d="M151.238044,160.121429 L124.809473,160.121429 C122.761906,159.986594 120.990446,158.647472 120.30233,156.714286 L83.1737587,42.4071429 C82.5666158,40.5357143 83.6023301,39 85.4737587,39 L111.90233,39 C113.941904,39.1430774 115.703009,40.4807321 116.388044,42.4071429 L153.530902,156.735714 C154.145187,158.585714 153.109473,160.121429 151.238044,160.121429 Z"
                  id="Path"
                />
                <path
                  d="M81.2809015,71.0474812 C81.0071158,70.4781386 80.594441,69.9868592 80.0809015,69.6189098 C79.0356855,68.8699522 77.6511024,68.7947334 76.5309015,69.4260526 C75.9179241,69.7720405 75.4604846,70.3393677 75.2523301,71.0117669 L47.1737587,155.854624 C46.5666158,157.726053 47.6023301,159.261767 49.4737587,159.261767 L75.9023301,159.261767 C77.9419038,159.11869 79.703009,157.781035 80.3880444,155.854624 L93.3523301,114.097481 C94.2880444,111.604624 93.9023301,109.811767 93.2380444,107.361767"
                  id="Path"
                />
              </g>
            </g>
          </svg> */}
            <span className="font-bold text-lg xl:text-xl tracking-tight">
              Absolute Code
            </span>
          </Link>
          <Button
            isMobileNavOpen={isMobileNavOpen}
            onClick={() => setMobileNavOpen(!isMobileNavOpen)}
          >
            <Hamburger
              isMobileNavOpen={isMobileNavOpen}
              isInverted={isInverted}
            />
          </Button>
          <Nav isMobileNavOpen={isMobileNavOpen}>
            <div className="text-sm px-8 md:p-0 xl:text-base font-medium">
              <Link
                to="/"
                className="block mt-4 text-3xl md:text-sm xl:text-base md:mr-6 md:inline-block md:mt-0 lg:mr-12 no-underline md:border-b border-transparent"
                activeStyle={{ borderColor: 'white' }}
              >
                Home
              </Link>
              <Link
                to="/blog"
                className="block mt-4 text-3xl border-0 md:text-sm xl:text-base md:mr-6 md:inline-block md:mt-0 lg:mr-12 no-underline md:border-b border-transparent"
                activeStyle={activeStyle}
              >
                Blog
              </Link>
              <Link
                to="/about"
                className="block mt-4 text-3xl border-0 md:text-sm xl:text-base md:mr-6 md:inline-block md:mt-0 lg:mr-12 no-underline md:border-b border-transparent"
                activeStyle={activeStyle}
              >
                About
              </Link>
              <Link
                to="/contact"
                className="block mt-4 text-3xl border-0 md:text-sm xl:text-base md:mr-0 md:inline-block md:mt-0 lg:mr-12 no-underline md:border-b border-transparent"
                activeStyle={activeStyle}
              >
                Contact
              </Link>
            </div>
          </Nav>
        </div>
      </Container>
    </StyledHeader>
  )
}

Header.defaultProps = {
  isInverted: false,
}

export default Header
