const theme = {
  breakpoints: ['550px', '768px', '1024px', '1280px'],
  colors: {
    black: '#000000',
    white: '#ffffff',
    transparent: 'hsla(0, 0%, 0%,0)',
    gray: [
      'hsl(0, 0%, 95%)',
      'hsl(0, 0%, 87%)',
      'hsl(0, 0%, 67%)',
      'hsl(0, 0%, 60%)',
      'hsl(0, 0%, 47%)',
      'hsl(0, 0%, 33%)',
      'hsl(0, 0%, 20%)',
      'hsl(0, 0%, 17%)',
      'hsl(0, 0%, 13%)',
      'hsl(0, 0%, 7%)',
    ],
    blue: [
      'hsl(212, 100%, 97%)',
      'hsl(212, 100%, 93%)',
      'hsl(212, 100%, 89%)',
      'hsl(212, 100%, 74%)',
      'hsl(212, 100%, 56%)',
      'hsl(212, 100%, 50%)',
      'hsl(212, 100%, 39%)',
      'hsl(212, 100%, 28%)',
      'hsl(212, 100%, 20%)',
      'hsl(212, 100%, 16%)',
    ],
    green: [
      '#f0fff4',
      '#dcffe4',
      '#bef5cb',
      '#85e89d',
      '#34d058',
      '#28a745',
      '#22863a',
      '#176f2c',
      '#165c26',
      '#144620',
    ],
    orange: [
      'hsl(18, 100%, 97%)',
      'hsl(18, 100%, 93%)',
      'hsl(18, 100%, 84%)',
      'hsl(18, 100%, 72%)',
      'hsl(18, 100%, 59%)',
      'hsl(18, 100%, 50%)',
      'hsl(18, 100%, 46%)',
      'hsl(18, 100%, 42%)',
      'hsl(18, 100%, 38%)',
      'hsl(18, 100%, 31%)',
    ],
    purple: [
      '#f5f0ff',
      '#e6dcfd',
      '#d1bcf9',
      '#b392f0',
      '#8a63d2',
      '#6f42c1',
      '#5a32a3',
      '#4c2889',
      '#3a1d6e',
      '#29134e',
    ],
    red: [
      '#ffeef0',
      '#ffdce0',
      '#fdaeb7',
      '#f97583',
      '#ea4a5a',
      '#d73a49',
      '#cb2431',
      '#b31d28',
      '#9e1c23',
      '#86181d',
    ],
    yellow: [
      '#fffdef',
      '#fffbdd',
      '#fff5b1',
      '#ffea7f',
      '#ffdf5d',
      '#ffd33d',
      '#f9c513',
      '#dbab09',
      '#b08800',
      '#735c0f',
    ],
  },
  fonts: {
    normal:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    mono:
      'SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace',
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72],
  fontWeights: {
    light: 300,
    normal: 400,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },
  textStyles: {
    h1: {
      fontSize: [7, null, 8],
      lineHeight: ['24px', '32px'],
      fontWeight: '400',
    },
    h2: {
      fontSize: ['16px', '24px'],
      lineHeight: ['24px', '32px'],
      fontWeight: '700',
    },
  },
  lineHeights: [1, 1.125, 1.25, 1.375, 1.5, 1.625, 2],
  space: [
    0,
    4,
    8,
    12,
    16,
    24,
    32,
    48,
    64,
    96,
    128,
    192,
    256,
    384,
    512,
    640,
    768,
  ],
}

export default theme
